import React from "react";
import '../../styles/App.css';
import Navbar from 'react-bootstrap/Navbar';
import Logo from '../../static/PergaevLogo.svg';


export default function NavBar() {
    return (
        <Navbar className="nav-height pt-md-5">
            <Navbar.Brand className="px-4"
                href="https://pergaev.com/">
                <img className="img-fluid" src={Logo} alt="Pergaev Bureau Logo"></img>
            </Navbar.Brand>
            <Navbar.Collapse className="justify-content-end">
                <Navbar.Text className="d-none d-lg-block px-4">
                    <u><a href="https://pergaev.com/">RU </a>/<a href="https://pergaev.com/en"> ENG</a></u>
                </Navbar.Text>
                <Navbar.Text className="d-none d-lg-block px-4">
                    <u><a href="tel:88005558436">8 800 555-84-36</a></u>
                </Navbar.Text>
                <Navbar.Text className="d-none d-lg-block px-4">
                    <hr />
                </Navbar.Text>
                <Navbar.Text className="d-flex justify-content-center align-items-center menu-circle mx-4">
                    <a className="text-decoration-none" href="https://pergaev.com/">На сайт</a>
                </Navbar.Text>
            </Navbar.Collapse>
        </Navbar>
    )
}
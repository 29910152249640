import { useRouteError } from "react-router-dom";
import '../../styles/App.css'

export default function ErrorPage() {
    const error = useRouteError();

    return (
        <div className="d-flex flex-column justify-content-center align-items-center text-center error-page">
            <h1 className="font-bold">Ошибочка вышла!</h1>
            <p className="font-medium">
                Пожалуйста, вернитесь на предыдущую страницу и проверьте правильность ввода данных
            </p>
            <p className="font-small"><i>Код ошибочки: {error.statusText || error.message}</i></p>
        </div>
    );
}
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'

import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Alert from 'react-bootstrap/Alert'
import ProgressBar from 'react-bootstrap/ProgressBar'

import DatePicker, { registerLocale } from "react-datepicker";
import ru from 'date-fns/locale/ru';
import 'react-datepicker/dist/react-datepicker.css'

import ToursService from '../../services/ToursService';
import Store from '../../store/store'

const ModalWindow = ({ type, active, setActive, deleteTour, updateTour }) => {
    registerLocale('ru', ru)

    const [tourName, setTourName] = useState('')
    const [tourDate, setTourDate] = useState(new Date())
    const [selectedFile, setSelectedFile] = useState()
    const [error, setError] = useState('')
    const [showProgressbar, setShowProgressbar] = useState(false)

    const { tourLoadingProgress } = Store

    async function handleAddNewTour(name, file) {
        if (name.length !== 0 && name.length <= 50 && selectedFile) {
            setShowProgressbar(true)
            await ToursService.addNewTour({ name: name, file: file })
            setActive(false)
            setTourName('')
            setSelectedFile()
            setShowProgressbar(false)
        } else {
            setError('Не заполнены все поля формы. Проверьте правильность ввода данных.')
        }
    }

    async function handleUpdateTour(name, key, date) {
        if (name.length === 0) {
            name = updateTour.name
        }

        if (name.length !== 0 && name.length <= 50) {
            const tour = {
                name: name,
                key: key,
                shareDate: date,
            }
            await ToursService.updateTour(tour, true)
            setActive(false)
            setTourName('')
        } else {
            setError('Не заполнены все поля формы. Проверьте правильность ввода данных.')
        }
    }

    switch (type) {
        case 'add':
            return (
                <Modal show={active} onHide={setActive} centered="true">
                    <Modal.Header closeButton>
                        <Modal.Title>Добавление тура</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='my-2'>
                        <Form.Label>Введите название тура:</Form.Label>
                        {error && <Form.Label className='text-danger'>{error}</Form.Label>}
                        <Form.Control
                            disabled={showProgressbar}
                            className='mb-3'
                            type="text" name='name'
                            placeholder="Название тура"
                            onChange={(e) => setTourName(e.target.value)} />
                        <Form.Label>Выберите архив с туром <span className='fw-bold'>(только .zip архивы)</span></Form.Label>
                        <Form.Control disabled={showProgressbar} className='' type="file" name='file' accept='.zip' onChange={(e) => setSelectedFile(e.target.files[0])} />
                        {showProgressbar && <ProgressBar className='mt-3' striped now={tourLoadingProgress} label={`${tourLoadingProgress}%`} />}
                    </Modal.Body>
                    {!showProgressbar &&
                        <Modal.Footer>
                            <Button className='' onClick={() => { handleAddNewTour(tourName, selectedFile) }}>Добавить</Button>
                            <Button className='' onClick={() => {
                                setTourName('')
                                setActive(false)
                                setError('')
                                setSelectedFile()
                            }}>Отмена</Button>
                        </Modal.Footer>
                    }

                </Modal>
            )
        case 'update':
            return (
                <Modal show={active} onHide={setActive} centered="true">
                    <Modal.Header closeButton>
                        <Modal.Title>Изменение тура {updateTour.name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='my-2'>
                        <Form.Label>Введите название тура:</Form.Label>
                        {error && <Form.Label className='text-danger'>{error}</Form.Label>}
                        <Form.Control
                            className='mb-3'
                            type="text"
                            name='name'
                            defaultValue={updateTour.name}
                            onChange={(e) => setTourName(e.target.value)} />

                        <Form.Label>Выберите новую дату экспирации ключа:</Form.Label>
                        <DatePicker
                            className='input-group-text mb-3'
                            dateFormat="dd.MM.yyyy"
                            locale="ru"
                            selected={tourDate}
                            onChange={(date) => {
                                setTourDate(date)
                            }} />

                        {/* <Form.Label>Выберите обновленный архив с туром <span className='fw-bold'>(только .zip архивы)</span></Form.Label>
                        <Form.Control className='' type="file" name='file' accept='.zip' onChange={(e) => setSelectedFile(e.target.files[0])} /> */}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className='' onClick={() => { handleUpdateTour(tourName, updateTour.key, tourDate) }}>Изменить</Button>
                        <Button className='' onClick={() => {
                            setTourName('')
                            setActive(false)
                            setError('')
                        }}>Отмена</Button>
                    </Modal.Footer>
                </Modal>
            )
        case 'delete':
            return (
                <Modal show={active} onHide={setActive} centered="true">
                    <Modal.Header closeButton>
                        <Modal.Title>Удаление тура</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Label>Вы уверены, что хотите удалить тур?<br />Это действие невозможно обратить</Form.Label>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className='' variant='danger' onClick={async () => {
                            await ToursService.deleteTour(deleteTour)
                            setActive(false)
                        }}>Удалить</Button>
                        <Button className='' onClick={() => setActive(false)}>Отмена</Button>
                    </Modal.Footer>
                </Modal>
            )

        default:
            break;
    }
}

export default observer(ModalWindow);
import '../styles/App.css'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import { useState, useContext } from 'react';
import { observer } from 'mobx-react-lite'
import { Context } from '../index.js';
import { useNavigate, useLocation, Navigate } from 'react-router-dom';
import LoadingPage from './components/LoadingPage'


const LoginPage = () => {
    const { store } = useContext(Context)

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')

    const navigate = useNavigate()
    const location = useLocation()
    const fromPage = location.state?.from?.pathname || '/admin'

    if (store.isLoading) { 
        return <LoadingPage />
    }

    if (store.isAuth) {
        return (
            <Navigate to={'/admin'} replace={true} />
        )
    }


    return (
        <>
            <div className='d-flex align-items-center justify-content-center'
                style={{ height: "100vh", width: "100vw" }}
            >
                <Card className='p-5 shadow border-0'>
                    <h2 className='font-medium m-auto text-center'>Вход в систему управления
                        <br></br>Виртуальными турами</h2>
                    <Form className='d-flex flex-column'>
                        <Form.Control className='mt-3 input'
                            placeholder='Имя пользователя'
                            name="username"
                            value={username}
                            id={"username"}
                            onChange={e => setUsername(e.target.value)}
                        />
                        <Form.Control className='mt-2 input'
                            placeholder='Пароль'
                            name="password"
                            type="password"
                            value={password}
                            id={"current-password"}
                            onChange={e => setPassword(e.target.value)}
                        />
                        <Button
                            variant='primary'
                            className='mt-3 align-self-start'
                            onClick={() => {
                                store.login(username, password)
                                if (store.isAuth) {
                                    navigate(fromPage, { replace: true })
                                }
                            }}
                        >Войти
                        </Button>
                    </Form>
                </Card>
            </div>

        </>
    )
}

export default observer(LoginPage)
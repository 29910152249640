import api from '../http'
import { makeAutoObservable } from 'mobx'
import Store from '../store/store'

export default class ToursService {
    static getTours() {
        return api.get('/gettours')
    }

    static async addNewTour(tour) {

        const formData = new FormData()
        const { setTourLoadingProgress } = Store
        const { name, file } = tour
        formData.append('name', name)
        formData.append('file', file)

        const config = {
            onUploadProgress: function (progressEvent) {
                const loadingProgress = Math.round((progressEvent.loaded / progressEvent.total) * 100)
                setTourLoadingProgress(loadingProgress)
            }
        }
        const tourName = await api.post('/add', formData, config)
        setTourLoadingProgress(0)
        return tourName
    }

    static updateTour(tour, isEditTour) {
        const Tour = {
            tourData: tour,
            isEditTour: isEditTour,
        }
        return api.post('/update', Tour)
    }

    static deleteTour(tour) {
        return api.post('/delete', tour)
    }

    static async tourKeyCheck(key) {
        let redirectResult = ''
        const response = await api.post(`/keycheck`, key)

        if (!response.data.length) {
            return redirectResult = "Произошла ошибка! Проверьте, пожалуйста, ключ и попробуйте еще раз"
        }

        const redirectKey = response.data[0].key
        const redirectDate = response.data[0].shareDate
        const now = new Date(Date.now())
        const tourDate = new Date(redirectDate)

        if (tourDate < now) {
            return redirectResult = `Данный ключ действовал до ${tourDate.toLocaleString()}.
            Если вам нужно продлить срок действия ключа, обратитесь, пожалуйста, к нашему менеджеру проекта.`
        }
        const TOURS_URL = process.env.REACT_APP_TOURS_URL
        redirectResult = TOURS_URL + '/' + redirectKey
        return redirectResult
    }
}
import React from "react";
import '../../styles/App.css';
import '../../styles/cube.css';
import { Canvas, useFrame } from '@react-three/fiber'
import { OrbitControls } from '@react-three/drei'
import { useSpring } from '@react-spring/core'
import Blob from './Blob'


export default function Card() {

    return (
        <>
            <Canvas className="canvas" dpr={[1, 2]}>
                <Blob  />
                <OrbitControls enablePan={false} enableZoom={false} maxPolarAngle={Math.PI / 2} minPolarAngle={Math.PI / 2} />
            </Canvas>
        </>

    )
}
import '../styles/App.css';
import { useContext } from 'react';
import { observer } from 'mobx-react-lite'
import { Context } from '../index.js';
import ReactGA from 'react-ga4'
import NavBar from './components/NavBar';
import Card from './components/Card'
import WelcomeText from './components/WelcomeForm'
import DefaultTourPage from './components/DefaultTourPage';

const ClientSideApp = () => {

  const { store } = useContext(Context)

  const GA_ID = process.env.REACT_APP_GA
  ReactGA.initialize(GA_ID)


  if (store.isTour) {
    return <DefaultTourPage tourUrl={store.tourUrl} />
  } else {
    return (
      <div className="container-fluid anim-fadein">
        <div>
          <NavBar />
        </div>
        <div className="d-flex justify-content-center row px-lg-5 anim-fadein">
          <div className="d-block col-lg-6">
            <WelcomeText />
          </div>
          <div className='d-none d-lg-block col-lg-1'>
          </div>
          <div className="d-none d-lg-block col-lg-5 bg-card anim-fadein">
            <Card />
          </div>
        </div>
      </div>
    );
  }
}

export default observer(ClientSideApp)

import '../../styles/App.css'

export default function ErrorPage() {

    return (
        <div className="d-flex flex-column justify-content-center align-items-center text-center error-page">
            <h1 className="font-bold">Такой страницы у нас нет! :C</h1>
            <p className="font-medium">
                Пожалуйста, вернитесь на предыдущую страницу и проверьте правильность ввода данных
            </p>
        </div>
    );
}
import '../styles/App.css'
import { useState, useEffect, useContext } from 'react';
import { Context } from '../index.js';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite'

import LOGO from '../static/PergaevLogo.svg'
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button'
import ModalWindow from './components/ModalWindow';
import Navbar from 'react-bootstrap/Navbar';
import UsersService from '../services/UsersService';



const Users_Managment = () => {

    const [users, setUsers] = useState([])
    const [reload, setReload] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)
    const [modalType, setModalType] = useState('')
    const [deleteUser, setDeleteUser] = useState('')
    const navigate = useNavigate()



    const { store } = useContext(Context)


    useEffect(() => {
        async function getUsers() {
            try {
                const response = await UsersService.getUsers();
                setUsers(response.data);
            } catch (e) {
                console.log(e);
            }
        }
        getUsers()
    }, [reload, modalOpen])

    function handleReload() {
        setReload(r => !r)
    }

    function handleTours() {
        navigate('/admin')
     }

    async function handleAddNewUser() {
        setModalOpen(true)
        setModalType('add')
    }

    async function handleDeleteUser(user) {
        // setModalOpen(true)
        // setModalType('delete')
        // setDeleteUser(user)
    }

    async function handlePasswordChange(user) {
        // await UsersService.changePassword(user)
        setReload(r => !r)
    }

    return (
        <>
            <div className='container'>
                <Navbar className="">
                    <Navbar.Brand className="" href="/"> <img src={LOGO} className="img-fluid" alt='Pergaev Logo' /> </Navbar.Brand>
                    <Navbar.Collapse className="d-flex justify-content-end align-items-center">
                        <Navbar.Text className="">
                            <Button className="m-1" onClick={() => handleTours()}>Управление турами</Button>
                            <Button className="m-1" onClick={() => handleReload()}>Обновить</Button>
                            <Button className="m-1" onClick={() => handleAddNewUser()}>Добавить пользователя</Button>
                            <Button className="m-1" onClick={() => store.logout()}>Выйти</Button>
                        </Navbar.Text>
                    </Navbar.Collapse>
                </Navbar>

                <div className='d-flex'>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Имя пользователя</th>
                                <th>Роль</th>
                                {/* <th>Сменить пароль</th>
                                <th>Удалить пользователя</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {
                                users.map((user, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{user.username}</td>
                                            <td>{user.role}</td>
                                            {/* <td><Button variant='primary' onClick={() => handlePasswordChange(user)}>Сменить пароль</Button></td>
                                            <td><Button variant='danger' onClick={() => handleDeleteUser(user)}>Удалить пользователя</Button></td> */}
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                </div>

            </div>
            <ModalWindow type={modalType} active={modalOpen} setActive={setModalOpen} deleteUser={deleteUser} />
        </>
    );
}

export default observer(Users_Managment)
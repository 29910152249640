import '../../styles/App.css'

export default function LoadingPage() {

    return (
        <div className="d-flex flex-column justify-content-center align-items-center text-center error-page">
            <h1 className="font-bold">Загрузка...</h1>
            <p className="font-medium">Подождите, пожалуйста</p>
        </div>
    );
}
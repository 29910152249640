import { observer } from 'mobx-react-lite'
import { useContext, useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { Context } from '../index.js'
import LoadingPage from './components/LoadingPage'



const AdminPanel_RequireAuth = ({ children }) => {

    const { store } = useContext(Context)

    useEffect(() => {
        if (localStorage.getItem('token')) {
            store.checkAuth()
        }
    }, [])

    if (store.isLoading) {
        return <LoadingPage />
    }
    if (!store.isAuth) {
        return (
            <Navigate to={'/login'} replace={true} />
        )
    }
    return (children)
}

export default observer(AdminPanel_RequireAuth)
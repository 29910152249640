import Iframe from 'react-iframe'
import '../../styles/App.css'
import { useRef, useEffect } from 'react'

export default function DefaultTourPage({ tourUrl }) {

    const scrollInto = useRef(null)
    useEffect(() => {
        scrollInto.current.scrollIntoView()
    },[])

    const BASE_URL = process.env.REACT_APP_BASE_URL

    const tourResultPath = `${BASE_URL}/${tourUrl}/`
    console.log(tourResultPath)
    return (
        <>
            <div className='iframe' ref={scrollInto}>
                <Iframe url={tourResultPath} className='iframe' />
            </div>
        </>
    );
}
import * as THREE from 'three'
import React, { Suspense, useState, useRef } from 'react'
import { useFrame } from '@react-three/fiber'
import { PerspectiveCamera, Environment, MeshDistortMaterial } from '@react-three/drei'
import { useSpring } from '@react-spring/core'
import { a } from '@react-spring/three'
import HDRI from '../../static/glass_passage_1k.hdr'

const AnimatedMaterial = a(MeshDistortMaterial)

export default function Blob() {
  const sphere = useRef()
  const light = useRef()
  const [mode, setMode] = useState(false)
  const [down, setDown] = useState(false)
  const [hovered, setHovered] = useState(false)

  // Make the bubble float and follow the mouse
  // This is frame-based animation, useFrame subscribes the component to the render-loop
  useFrame((state) => {
    light.current.position.x = state.mouse.x * 20
    light.current.position.y = state.mouse.y * 20
    if (sphere.current) {
      sphere.current.position.x = THREE.MathUtils.lerp(sphere.current.position.x, hovered ? state.mouse.x / 2 : 0, 0.2)
      sphere.current.position.y = THREE.MathUtils.lerp(
        sphere.current.position.y,
        Math.sin(state.clock.elapsedTime / 1.5) / 6 + (hovered ? state.mouse.y / 2 : 0),
        0.2
      )
    }
  })

  // Springs for color and overall looks, this is state-driven animation
  // React-spring is physics based and turns static props into animated values
  const [{ wobble, coat, color, ambient, env }] = useSpring(
    {
      wobble: down ? 1.10 : hovered ? 1 : 1,
      coat: mode && !hovered ? 1 : 1,
      ambient: mode && !hovered ? 1.5 : 1,
      env: mode && !hovered ? 0.4 : 1,
      color: hovered ? '#0030FF' : mode ? '#303030' : '#FFF',
      config: (n) => n === 'wobble' && hovered && { mass: 20, tension: 1000, friction: 10 }
    },
    [mode, hovered, down]
  )

  return (
    <>
      <PerspectiveCamera makeDefault position={[0, 0, 5]} fov={65}>
        <a.ambientLight intensity={ambient} />
        <a.pointLight ref={light} position-z={30} intensity={env} color="#FFF" />
      </PerspectiveCamera>
      <Suspense fallback={null}>
        <a.mesh
          ref={sphere}
          scale={wobble}
          onPointerOver={() => setHovered(true)}
          onPointerOut={() => setHovered(false)}
          onPointerDown={() => setDown(true)}
          onPointerUp={() => {
            setDown(false)
            setMode(!mode)
          }}>
          <sphereGeometry args={[1.5, 32, 32]} />
          <AnimatedMaterial color={color} envMapIntensity={env} clearcoat={coat} clearcoatRoughness={0} metalness={0.5} />
        </a.mesh>
        <Environment files={HDRI} />
      </Suspense>
    </>
  )
}

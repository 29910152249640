/* eslint-disable react/jsx-pascal-case */
import React, { createContext } from 'react';
import ReactDOM from 'react-dom/client';

import './styles/index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/fonts/AtypDisplay-Medium.ttf'
import './styles/fonts/AtypDisplay-Regular.ttf'
import './styles/fonts/AtypDisplay-Semibold.ttf'

import { BrowserRouter, Routes, Route } from 'react-router-dom'

import ClientSideApp from './clientSideApp/ClientSideApp';
import Store from './store/store';

import ErrorPage from "./adminPanel/components/ErrorPage";
import Page404 from "./adminPanel/components/Page404";
import AdminPanel_PanoManager from './adminPanel/AdminPanel_PanoManager';
import AdminPanel_LoginPage from './adminPanel/AdminPanel_LoginPage';
import AdminPanel_RequireAuth from './adminPanel/AdminPanel_RequireAuth';
import AdminPanel_UsersManagment from './adminPanel/AdminPanel_UsersManagment';


const root = ReactDOM.createRoot(document.getElementById('root'));

const store = Store

export const Context = createContext({
    store,
})

root.render(
    <Context.Provider value={{
        store
    }}>
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<ClientSideApp />} errorElement={<ErrorPage />} />
                <Route path='*' element={<Page404 />} errorElement={<ErrorPage />} />

                <Route path='/login' element={<AdminPanel_LoginPage />} errorElement={<ErrorPage />} />
                <Route path='/admin' element={<AdminPanel_RequireAuth><AdminPanel_PanoManager /></AdminPanel_RequireAuth>} errorElement={<ErrorPage />} />
                <Route path='/users' element={<AdminPanel_RequireAuth><AdminPanel_UsersManagment /></AdminPanel_RequireAuth>} errorElement={<ErrorPage />} />
            </Routes>
        </BrowserRouter>
    </Context.Provider >


);

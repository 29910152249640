import { makeAutoObservable } from 'mobx'
import AuthService from '../services/AuthService'
import axios from 'axios'


class Store {

    user = {}
    isAuth = false
    isLoading = false

    isTour = false
    tourUrl

    tourAdd

    tourLoadingProgress = 0

    constructor() {
        makeAutoObservable(this)
    }

    setTourLoadingProgress = (progress) => {
        this.tourLoadingProgress = progress
    }

    setIsTour(bool) {
        this.isTour = bool
    }

    setAuth(bool) {
        this.isAuth = bool
    }

    setUser(user) {
        this.user = user
    }

    setLoading(isLoading) {
        this.isLoading = isLoading
    }

    setTourUrl(url) {
        this.tourUrl = url
    }

    async showTour(bool) {
        this.setIsTour(bool)
    }

    async login(username, password) {
        try {
            const response = await AuthService.login(username, password)
            localStorage.setItem('token', response.data.accessToken)

            this.setAuth(true)
            this.setUser(response.data.user)
        } catch (error) {
            alert(error.response?.data?.message)
        }
    }

    async logout() {
        try {
            const response = await AuthService.logout()
            localStorage.removeItem('token')
            this.setAuth(false)
            this.setUser({})
        } catch (error) {
            alert(error.response?.data?.message)
        }
    }

    async checkAuth() {
        this.setLoading(true)
        try {
            const API_URL = process.env.REACT_APP_API_URL
            const response = await axios.get(`${API_URL}/refresh`, { withCredentials: true, baseURL: API_URL })
            localStorage.setItem('token', response.data.accessToken)
            this.setAuth(true)
            this.setUser(response.data.user)
            return true
        } catch (error) {
            console.log("Ошибка проверки авторизации: " + error.response?.data?.message)
            return false
        } finally {
            this.setLoading(false)
        }
    }
}

export default new Store()
import '../styles/App.css'
import { useState, useEffect, useContext } from 'react';
import { Context } from '../index.js';

import { observer } from 'mobx-react-lite'
import LOGO from '../static/PergaevLogo.svg'
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button'
import ToursService from '../services/ToursService';
import ModalWindow from './components/ModalWindow';
import Navbar from 'react-bootstrap/Navbar';
import { useNavigate } from 'react-router-dom';

const AP_Manage = () => {

    const navigate = useNavigate()

    const [allTours, setAllTours] = useState([])
    const [deleteTour, setDeleteTour] = useState('')
    const [updateTour, setUpdateTour] = useState('')

    const [reload, setReload] = useState(false)

    const [modalOpen, setModalOpen] = useState(false)
    const [modalType, setModalType] = useState('')

    const { store } = useContext(Context)


    useEffect(() => {
        async function getTours() {
            try {
                if (store.isAuth) {
                    const response = await ToursService.getTours();
                    const sort = response.data.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
                    setAllTours(sort);
                } else {
                    setAllTours([])
                }

            } catch (e) {
                console.log(e);
            }
        }
        getTours()
    }, [reload])

    function handleTourDate(tour) {
        if (!(tour === '')) {
            let date = new Date(tour.shareDate)
            const now = new Date(Date.now())
            if (date < now) {
                return (
                    <span className='fw-bold text-danger'>{date.toLocaleString()}</span>
                )
            }
            return date.toLocaleString()

        }
    }

    async function handleReload() {
        setReload(r => !r)
    }

    function handleUsers() {
        navigate('/users')
    }

    async function handleLogout() {
        await store.logout()
    }

    async function handleAddNewTour() {
        setModalOpen(true)
        setModalType('add')
    }

    async function handleUpdateTour(tour, isKeyUpdate) {
        if (isKeyUpdate) {
            await ToursService.updateTour(tour)
        } else {
            setModalOpen(true)
            setModalType('update')
            setUpdateTour(tour)
        }
        setReload(r => !r)
    }

    async function handleDeleteTour(tour) {
        setModalOpen(true)
        setModalType('delete')
        setDeleteTour(tour)
    }

    return (
        <>
            <div className='container'>
                <Navbar className="">
                    <Navbar.Brand className="d-none d-lg-flex" href="/"> <img src={LOGO} className="img-fluid" alt='Pergaev Logo' /> </Navbar.Brand>
                    <Navbar.Collapse className="d-flex justify-content-end align-items-center">
                        <Navbar.Text className="">
                            {store.user.role === 'Admin' ?
                                <Button variant='outline-primary' className="m-1" onClick={() => handleUsers()}>Пользователи</Button> : null}
                                <Button className="m-1" onClick={() => handleReload()}>Обновить список туров</Button>
                                <Button variant='success' className="m-1" onClick={() => handleAddNewTour()}>Добавить тур</Button>
                                <Button variant='outline-secondary' className="m-1" onClick={() => handleLogout()}>Выйти</Button>
                        </Navbar.Text>
                    </Navbar.Collapse>
                </Navbar>

                <div className='d-flex'>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th onClick={(e) => console.log(e.target)}>Номер тура</th>
                                <th>Название тура</th>
                                <th>Ключ доступа</th>
                                <th>Дата экспирации ключа</th>
                                <th>Изменение тура</th>
                                <th>Обновление ключа</th>
                                {store.user.role === 'Admin' ? <th>Удаление тура</th> : null}
                            </tr>
                        </thead>
                        <tbody>
                            {
                                allTours.map((tour, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{tour.name}</td>
                                            <td>{tour.key}</td>
                                            <td>{handleTourDate(tour)}</td>
                                            <td><Button variant='outline-primary' onClick={() => handleUpdateTour(tour, false)}>Изменить тур</Button></td>
                                            <td><Button variant='outline-primary' onClick={() => handleUpdateTour(tour, true)}>Обновить тур</Button></td>
                                            {store.user.role === 'Admin' ?
                                                <td>
                                                    <Button
                                                        variant='outline-danger'
                                                        onClick={() => handleDeleteTour(tour)}>Удалить тур
                                                    </Button>
                                                </td> :
                                                null
                                            }
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                </div>

            </div>
            <ModalWindow type={modalType} active={modalOpen} setActive={setModalOpen} deleteTour={deleteTour} updateTour={updateTour} />
        </>
    );
}

export default observer(AP_Manage)
import '../../styles/App.css';
import React, { useState, useContext } from 'react';
import ToursService from "../../services/ToursService.js";
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { observer } from 'mobx-react-lite'
import { Context } from '../../index.js';


const TOURS_URL = process.env.REACT_APP_TOURS_URL

const WelcomeText = () => {

    const [tourKey, setTourKey] = useState('')
    const [redirectError, setRedirectError] = useState()
    const { store } = useContext(Context)

    const handleSubmit = async (e) => {
        e.preventDefault()

        const redirectResult = await ToursService.tourKeyCheck({ enteredKey: tourKey })
        if (redirectResult.includes(TOURS_URL)) {
            store.setIsTour(true)
            store.setTourUrl(redirectResult)
        } else {
            setRedirectError(redirectResult)
        }

    }

    return (
        <Container className="d-flex justify-content-center align-items-center full-height">
            <div>
                <h1 className="font-bold mb-4">Добро пожаловать</h1>
                <h6 className="font-medium mb-5">В систему просмотра виртуальных туров Pergaev Bureau.<br></br><br></br>
                    Пожалуйста, введите ключ доступа в поле ниже для перехода к вашему виртуальному туру:
                </h6>
                {redirectError && (<h6 className="font-small text-danger mb-3"><strong>
                    {redirectError}
                </strong></h6>)}

                <Form onSubmit={handleSubmit}>
                    <Form.Control
                        className="font-small input"
                        placeholder="Ключ доступа"
                        value={tourKey}
                        size="md"
                        onChange={e => setTourKey(e.target.value)}>
                    </Form.Control>
                    <Button className="font-small custom-button mt-4" type="submit">
                        Смотреть тур
                    </Button>
                </Form>
            </div>
        </Container>
    )
}

export default observer(WelcomeText)